<template>
  <div class="container">
    <b-card>
      <b-row v-for="(item, index) in items" :key="item.id" class="mb-1">
        <b-col md="8">
          <li style="list-style-type: none;"> <span>{{ index + 1 }} -</span> {{ item.filters }}</li>
        </b-col>

        <b-col md="2" v-if="hasPermission('edit_conditions')">
          <b-form-checkbox @change="changeCondition(item.id, item.open)" :checked="item.open == 1"
            class="custom-control-success" name="check-button" switch />
        </b-col>
      </b-row>
    </b-card>


    <!-- <b-card>
   <h1>  Manual Checks </h1> 
    </b-card> -->
    <!-- <a :href='`${baseUrl}/assign`'>
      <b-button class="mx-2">
        Assign Auditors
      </b-button>
    </a>

    <a :href='`${baseUrl}/unassign`'>
      <b-button class="mx-2">
        Un-Assign Auditors
      </b-button>
    </a> -->
    <b-button @click="$store.dispatch('setting/pullData')" class="btn btn-success mr-2 mt-5"> Assign Auditors</b-button>

    <b-button @click="$store.dispatch('setting/deleteData')" class="btn btn-danger mt-5"> Delete Auditors </b-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import show from "@/view/patients/components/show";
import {
  BTab,
  BCardText,
  BCard,
  BCol,
  BRow,
  BTable,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFromRadioGroup,
  BFormRadio,
  BCardCode,
  BFormCheckbox,
} from "bootstrap-vue";
import List from "@/views/components/info/list";
import vSelect from "vue-select";
export default {
  name: "patient_case",
  components: {
    vSelect,
    BTab,
    BCardText,
    BCard,
    BCol,
    BRow,
    List,
    BTable,
    BForm,
    BFormGroup,
    BFromRadioGroup,
    BFormInput,
    BFormRadio,
    BButton,
    BCardCode,
    BFormCheckbox,
  },
  data() {
    return {
      report_status: [],
      toggle_check: null,
      checked: true,
      //  case_review: {}
    };
  },
  computed: {
    ...mapGetters({
      items: "setting/items",
    }),

    reports_status() {
      let reports_status = [
        {
          id: 1,
          type: "Medical",
        },
        {
          id: 2,
          type: "Managerial",
        },
        {
          id: 3,
          type: "Financial",
        },
      ];

      return reports_status;
    },
  },

  mounted() {
    this.init();
  },
  methods: {
    changeCondition(id, checked) {
      if (checked == 1) {
        checked = 0;
      } else if (checked == 0) {
        checked = 1;
      }
      this.toggle_check = checked;

      this.$store
        .dispatch("setting/put", {
          id: id,
          query: {
            open: this.toggle_check,
          },
        })
        .then((_) => {
          this.init();
        })
        .catch((error) => {
          this.errorsdata = this.handleBackendError(error.response.data.errors);
        });
    },
    changeConditionType(id, condition_type) {
      console.log(condition_type);
      this.$store
        .dispatch("setting/put", {
          id: id,
          query: {
            type: condition_type,
          },
        })
        .then((_) => {
          this.init();
        })
        .catch((error) => {
          this.errorsdata = this.handleBackendError(error.response.data.errors);
        });
    },
    init(query) {
      this.$store.dispatch("setting/settings", { query }).then((response) => {
        console.log(response);
      });
    },
  },
};
</script>

<style scoped>
.size-min {
  font-size: 10px;
}
</style>
